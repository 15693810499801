<template>
  <div>
    <div class="px-2 d-flex justify-content-between align-items-center mb-2">
      <h1 class="float-left pr-1 mb-0">Cadastro da operação</h1>

      <div class="d-flex">
        <b-button
          variant="outline-secondary"
          class="mr-1 font-weight-bolder"
          @click="$router.push({ name: 'emissions.index' })"
        >
          <span class="text-dark"> Voltar </span>
        </b-button>
        <b-button
          variant="outline-secondary"
          class="font-weight-bolder bg-light-secondary"
          @click="onSubmit"
        >
          Salvar
        </b-button>
      </div>
    </div>
    <operation-form ref="operationForm" />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import OperationForm from '@/views/admin/emissions/components/OperationsForm.vue'

export default {
  name: 'OperationEdit',
  components: {
    BButton,
    OperationForm,
  },
  methods: {
    async onSubmit() {
      await this.$refs.operationForm.onSubmit()
    },
  },
}
</script>

<style lang="scss">
.label-text-nowrap {
  legend {
    white-space: nowrap;
  }
}

.w-75px {
  width: 75px;
}

.invalid-selection .vs__dropdown-toggle {
  border-color: #fc065e;
}
</style>
